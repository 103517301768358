import React, { memo, FunctionComponent, useContext } from 'react';
import { Form, Field } from 'react-final-form';
import styled from 'styled-components';
import { Flex, InputField, Text, Box } from 'mdlkit';
import Router from 'next/router';
import { FormattedMessage, useIntl } from 'react-intl';
import { usernameComposed } from '../../utils/login/validations';
import {
  StyledForm,
  FormSubmitButton,
  CustomSignInButton,
  CustomFormSubmitButton,
  GrayLine,
  GrayLinesSection,
  OrText,
} from '../shared/Styles';
import { NextHyperlink } from '../shared';
import { ACCOUNT_RECOVERY_URL } from '../../constants/login';
import SimplePasswordField from '../register/PasswordField/SimplePasswordField';
import { useCustomizable } from '../app/CustomizableProvider';
import { parseCustomParams } from '../../utils/customParams';
import { BrandingContext } from '../app/BrandingProvider';

interface Props {
  onSubmit: (values: {
    usernameOrEmail: string;
    hiddenPassword: string;
  }) => void;
  displayCreateAccount?: boolean;
}

const PasswordContainer = styled(Box)`
  display: none;
  input {
    display: none;
  }
`;

const LoginForm: FunctionComponent<Props> = ({
  onSubmit,
  displayCreateAccount = true,
}: Props) => {
  const { isCustomizable, params } = useCustomizable();
  const { branding } = useContext(BrandingContext);
  const displayCustomSignIn =
    branding?.customSignIn.seamlessSso &&
    branding?.customSignIn.customSignInUrl;
  const { formatMessage: f } = useIntl();
  const externalCustomSignIn = `${f({ id: 'login.customSignInSeamlessSso' })} ${
    branding?.customSignIn.customSignInAffName ||
    branding?.affiliation.description
  }`;

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ usernameOrEmail: '', hiddenPassword: '' }}
      render={({
        handleSubmit,
        submitting,
        hasSubmitErrors,
        dirtySinceLastSubmit,
      }) => (
        <StyledForm onSubmit={handleSubmit} spellCheck={false}>
          <Field
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex={1}
            id="usernameOrEmail"
            label={f({ id: 'login.label.usernameOrEmail' })}
            name="usernameOrEmail"
            validate={usernameComposed}
            component={InputField}
            autoCapitalize="off"
            autoCorrect="off"
            spellCheck={false}
          />
          <PasswordContainer>
            <Field
              id="hiddenPassword"
              name="hiddenPassword"
              component={SimplePasswordField}
              aria-hidden="true"
            />
          </PasswordContainer>
          {displayCustomSignIn ? (
            <>
              <CustomFormSubmitButton
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex={2}
                id="validateUserBtn"
                type="submit"
                size="large"
                disabled={
                  submitting || (hasSubmitErrors && !dirtySinceLastSubmit)
                }
              >
                Continue
              </CustomFormSubmitButton>
            </>
          ) : (
            <>
              <FormSubmitButton
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex={2}
                id="validateUserBtn"
                type="submit"
                size="large"
                disabled={
                  submitting || (hasSubmitErrors && !dirtySinceLastSubmit)
                }
              >
                Continue
              </FormSubmitButton>
            </>
          )}
          <Flex column align="center">
            <NextHyperlink
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex={3}
              href={parseCustomParams(ACCOUNT_RECOVERY_URL, params)}
              mb="xs"
              bold
              data-track-event
              data-track={JSON.stringify({
                controlName: 'forgot-username-link',
                controlText: f({ id: 'login.forgotUsernameLink' }),
              })}
            >
              <FormattedMessage id="login.forgotUsernameLink" />
            </NextHyperlink>
            {branding && displayCustomSignIn ? (
              <>
                <GrayLinesSection>
                  <GrayLine></GrayLine>
                  <OrText>
                    <FormattedMessage id="login.orOption" />
                  </OrText>
                  <GrayLine></GrayLine>
                </GrayLinesSection>
                <CustomSignInButton
                  type="button"
                  size="medium"
                  onClick={() =>
                    Router.push(branding?.customSignIn.customSignInUrl)
                  }
                  data-track-event
                  data-track={JSON.stringify({
                    controlName: 'affiliation-external-signin-link',
                    controlText: externalCustomSignIn,
                    destinationURL: branding?.customSignIn.customSignInUrl,
                  })}
                >
                  {externalCustomSignIn}
                </CustomSignInButton>
              </>
            ) : null}
            {displayCreateAccount && !isCustomizable ? (
              <>
                <Text pt={1}>
                  Don’t have an account?{' '}
                  <NextHyperlink
                    // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                    tabIndex={4}
                    href="/register"
                    bold
                    data-track-event
                    data-track={JSON.stringify({
                      controlName: 'create-account-username-link',
                      controlText: 'Create an account now',
                    })}
                  >
                    Create an account now
                  </NextHyperlink>
                </Text>
              </>
            ) : null}
          </Flex>
        </StyledForm>
      )}
    />
  );
};

export default memo(LoginForm);
