import React, { memo, FunctionComponent } from 'react';
import { Form, Field } from 'react-final-form';
import { ButtonLink } from 'mdlkit';
import { FormattedMessage, useIntl } from 'react-intl';
import { required } from '../../utils/shared/validations';
import { StyledForm, FormSubmitButton, StyledFlex } from '../shared/Styles';
import SimplePasswordField from '../register/PasswordField/SimplePasswordField';

interface Props {
  onSubmit: (values: { password: string }) => void;
  OnForgotPassword: () => void;
  password: string;
  userStatusLocked: boolean;
}

const PasswordForm: FunctionComponent<Props> = ({
  onSubmit,
  OnForgotPassword,
  password,
  userStatusLocked,
}: Props) => {
  const { formatMessage: f } = useIntl();
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ password }}
      render={({
        handleSubmit,
        submitting,
        hasSubmitErrors,
        dirtySinceLastSubmit,
        values,
      }) => (
        <StyledForm onSubmit={handleSubmit} spellCheck={false}>
          <Field
            id="password"
            label={f({ id: 'password' })}
            name="password"
            values={values}
            validate={required}
            component={SimplePasswordField}
          />
          <StyledFlex mt="10px">
            {!userStatusLocked && (
              <ButtonLink
                data-track-event
                data-track={JSON.stringify({
                  controlName: 'forgot-password-link',
                  controlText: f({ id: 'login.forgotPasswordLink' }),
                })}
                bold={false}
                onClick={OnForgotPassword}
                type="button"
              >
                <FormattedMessage id="login.forgotPasswordLink" />
              </ButtonLink>
            )}
          </StyledFlex>
          <FormSubmitButton
            id="createAccountBtn"
            type="submit"
            size="large"
            disabled={submitting || (hasSubmitErrors && !dirtySinceLastSubmit)}
          >
            Sign in
          </FormSubmitButton>
        </StyledForm>
      )}
    />
  );
};

export default memo(PasswordForm);
