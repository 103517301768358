import apiFetch from '../core/apiFetch';
import { checkStatus, handleError } from '../../utils/api';
import { getAuthTokenAsync } from '../../utils/authToken';
import { UNEXPECTED_ERROR } from '../../constants/errors';
import { LoginUser } from '../../interfaces/login';

export interface ErrorResponse {
  error: {
    code: string;
    message: string[];
  };
}

export interface SuccessResponse {
  user: LoginUser;
}

const getLoginUserAsync = async (
  userId: string
): Promise<ErrorResponse | SuccessResponse> => {
  const token = await getAuthTokenAsync();
  const url = `api/v1/login/users/${userId}`;

  try {
    const response = await apiFetch(token, url, {
      method: 'GET',
    });

    await checkStatus(response);

    const { user } = await response.json();

    return { user: { ...user, firstName: user.first_name } };
  } catch (error) {
    const { message } = error;

    handleError('unexpected status - get login user', error);
    return { error: { code: UNEXPECTED_ERROR, message: [message] } };
  }
};

export default getLoginUserAsync;
