import React, { Fragment, FunctionComponent, useState, useEffect } from 'react';
import { ButtonLink } from 'mdlkit';
import { useIntl, FormattedMessage } from 'react-intl';
import { Modal } from '../../../core';
import PasswordResetForm from './PasswordResetForm';
import { LoginUser } from '../../../interfaces/login';
import searchLoginUserAsync, {
  SuccessResponse,
  ErrorResponse,
} from '../../../api/login/searchLoginUserAsync';
import getLoginUserAsync from '../../../api/login/getLoginUserAsync';
import toast from '../../../utils/toast';

interface Props {
  username?: string;
  birthdate?: string;
  userId?: string;
}

const LockedUser: FunctionComponent<Props> = ({
  username,
  birthdate,
  userId,
}: Props) => {
  const { formatMessage: f } = useIntl();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [user, setUser] = useState<LoginUser | null>(null);

  const getLoginUser = async () => {
    let resp: null | SuccessResponse | ErrorResponse = null;
    if (typeof username !== 'undefined') {
      resp = await searchLoginUserAsync(username, birthdate);
    } else if (typeof userId !== 'undefined') {
      resp = await getLoginUserAsync(userId);
    } else {
      throw new Error('username or userId is needed to get a login user');
    }

    if ('error' in resp) {
      const { error } = resp;
      toast.error(error.message.join(', '));
    } else if ('user' in resp) {
      const { user: u } = resp;
      setUser(u);
    } else {
      throw new Error('unexpected state');
    }
  };

  useEffect(() => {
    getLoginUser();
  }, []);

  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  return (
    <Fragment>
      <FormattedMessage
        id="lockedUserError.toaster"
        values={{
          unlockLink: (
            <ButtonLink onClick={open}>
              <FormattedMessage id="lockedUserError.clickHere" />
            </ButtonLink>
          ),
        }}
      />
      {user ? (
        <Modal
          isOpen={showDialog}
          onDismiss={close}
          ariaLabel={f({ id: 'lockedUserError.modalAriaLabel' })}
        >
          <PasswordResetForm user={user} />
        </Modal>
      ) : null}
    </Fragment>
  );
};

export default LockedUser;
