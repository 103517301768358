import React, { FunctionComponent, Fragment, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { useIntl, FormattedMessage } from 'react-intl';
import { Flex, RadioField, Button, Text, Heading, Box } from 'mdlkit';
import { LoginUser, PasswordReset } from '../../../interfaces/login';
import { EMAIL, SMS } from '../../../constants/login';
import postPasswordResetAsync from '../../../api/login/postPasswordResetAsync';

interface PasswordResetProps {
  user: LoginUser;
}

const PasswordResetForm: FunctionComponent<PasswordResetProps> = ({
  user,
}: PasswordResetProps) => {
  const { formatMessage: f } = useIntl();
  const [successMessage, setSuccessMessage] = useState<null | string>(null);

  const onSubmit = async (values: PasswordReset) => {
    const resetResp = await postPasswordResetAsync(values);

    if ('success' in resetResp) {
      setSuccessMessage(resetResp.success);
    }
  };

  return (
    <Fragment>
      <Heading as="h2">
        <FormattedMessage id="lockedUserError.modalHeader" />
      </Heading>
      <Box pt="sm">
        <Text>
          <FormattedMessage id="lockedUserError.modalAccountLocked" />
        </Text>

        <Text pt="sm">
          <FormattedMessage id="lockedUserError.modalReceiveCode" />
        </Text>
        <Form
          onSubmit={onSubmit}
          initialValues={{ userId: user.id, verificationMethod: EMAIL }}
          render={({ handleSubmit, submitting, valid }) => (
            <Flex
              as="form"
              column
              align="center"
              pt="md"
              onSubmit={handleSubmit}
            >
              <Field name="userId" type="hidden" component="input" />

              <div
                role="radiogroup"
                aria-labelledby={f({
                  id: 'lockedUserError.modalVerificationMethod',
                })}
              >
                {' '}
                {user.email ? (
                  <Field
                    id="verificationMethod_email"
                    type="radio"
                    name="verificationMethod"
                    label={f(
                      { id: 'accountRecovery.emailOption' },
                      { email: user.email }
                    )}
                    component={RadioField}
                    value={EMAIL}
                  />
                ) : null}
                {user.phone ? (
                  <Field
                    id="verificationMethod_phone"
                    type="radio"
                    name="verificationMethod"
                    label={f(
                      { id: 'accountRecovery.smsOption' },
                      { phone: user.phone }
                    )}
                    component={RadioField}
                    value={SMS}
                  />
                ) : null}
              </div>

              <Button
                mt="md"
                id="submitPasswordReset"
                type="submit"
                size="large"
                disabled={submitting || !valid}
              >
                <FormattedMessage id="lockedUserError.modalSendLinkButton" />
              </Button>

              {successMessage ? (
                <Text bold pt="sm">
                  {successMessage}
                </Text>
              ) : null}
            </Flex>
          )}
        />
      </Box>
    </Fragment>
  );
};

export default PasswordResetForm;
