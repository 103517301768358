import {
  composeValidators,
  required,
  allNumbers,
  exactLengthDigits,
  usernameOrEmail,
} from '../shared/validations';

// eslint-disable-next-line import/prefer-default-export
export const code = composeValidators(
  required,
  allNumbers,
  exactLengthDigits(5)
);

export const usernameComposed = composeValidators(required, usernameOrEmail);

export const numberOfParamsFromCurrentHref = () => {
  if (typeof window !== 'undefined') {
    return window.location.search?.match(/=/g)?.length || 0;
  }

  return 0;
};

export const redirectToValue = () => {
  const paramString = window.location.search.split('?')[1];
  const queryString = new URLSearchParams(paramString);

  const queries = Array.from(queryString.entries());
  const redir = queries.find(([key]) => key === 'redirect_to');

  return redir ? redir[1] : '';
};
