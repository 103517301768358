/* eslint react/no-array-index-key: 0 */
import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { Box, Text, Flex, ButtonLink, LoadingIcon } from 'mdlkit';
import { ValidateUser } from '../../interfaces/login';
import { getTimestamp } from '../../utils/shared/helpers';

interface Props {
  userList: ValidateUser[];
  onBack: () => void;
  onSelectUser: (user: ValidateUser) => void;
}

const AccountWrapper = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 30px 20px;
  border-radius: 10px;
  box-shadow: 0px 3px 10px 0px rgb(0 0 0 / 5%);
  cursor: pointer;
  img {
    width: 100px;
  }

  &:active,
  &:hover {
    border: solid 2px ${({ theme }) => theme.colors.primary};
  }
`;

const ChooseAccount: FunctionComponent<Props> = ({
  userList,
  onBack,
  onSelectUser,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleOnClick = (user) => {
    setIsLoading((prev) => !prev);
    onSelectUser(user);
  };

  const comparator = (a: ValidateUser, b: ValidateUser) =>
    getTimestamp(b.lastSignedIn) - getTimestamp(a.lastSignedIn);
  return (
    <Box>
      <Text align="center" margin="20px 0px" fontSize={1}>
        Multiple accounts found. Please choose one
      </Text>
      {isLoading ? (
        <LoadingIcon
          width="50px"
          height="50px"
          color="primary"
          backgroundColor="white"
        />
      ) : (
        <>
          <Box>
            {userList.sort(comparator).map((user, index) => (
              <AccountWrapper
                key={`${index}-${user.username}`}
                onClick={() => handleOnClick(user)}
                data-track-event
                data-track={JSON.stringify({
                  controlName: 'multiple-account-selection',
                  controlText: user.affiliation?.name ?? '',
                })}
              >
                <Text>
                  Last signed in: {user.lastSignedIn ? user.lastSignedIn : '-'}
                </Text>
                {user.affiliation && (
                  <img
                    src={user.affiliation.logo}
                    alt={`${user.affiliation.name} Logo`}
                  />
                )}
              </AccountWrapper>
            ))}
          </Box>
          <Box textAlign="center" mt={30}>
            <ButtonLink onClick={onBack}>Back</ButtonLink>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ChooseAccount;
