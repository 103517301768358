import React, { useState } from 'react';
import {
  Box,
  Flex,
  Text,
  ButtonLink,
  Icon,
  TransparentButton,
  mq,
} from 'mdlkit';
import styled from 'styled-components';
import { SpaceProps } from 'styled-system';
import { ValidateUser } from '../../interfaces/login';

const ChangeUserWrapper = styled(Flex)`
  position: relative;

  ${ButtonLink} {
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    ${mq({
      display: ['none', 'block'],
    })};
  }

  > ${TransparentButton} {
    ${mq({
      display: ['block', 'none'],
    })};
  }
`;

const OptionsList = styled.ul`
  position: absolute;
  top: 30px;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  z-index: 1;
  border-radius: 5px;
  box-shadow: ${({ theme }) => theme.boxShadows[1]};
  padding: 10px 0px;

  li {
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => theme.colors.primary};
    }
  }
`;

interface Props extends SpaceProps {
  selectedUser: ValidateUser;
  userList: ValidateUser[] | undefined;
  onSelectUser: (user: any) => void;
}

const MultipleUserSelector = ({
  selectedUser,
  userList,
  onSelectUser,
  ...rest
}: Props) => {
  const [showUsers, setShowUsers] = useState<boolean>(false);

  const handleSelectUser = (user) => {
    onSelectUser(user);
    setShowUsers(false);
  };

  return (
    <Box textAlign="center" {...rest}>
      {userList ? (
        <ChangeUserWrapper justify="center">
          <Text mr={10}>{selectedUser.username}</Text>
          <ButtonLink onClick={() => setShowUsers((prev) => !prev)}>
            Change
          </ButtonLink>
          <TransparentButton onClick={() => setShowUsers((prev) => !prev)}>
            <Icon name="chevronDown" size={14} />
          </TransparentButton>
          {showUsers && (
            <OptionsList>
              {userList.map((user) => (
                <li key={`${user.username}`}>
                  <TransparentButton
                    padding="10px"
                    onClick={() => handleSelectUser(user)}
                  >
                    {user.username}
                  </TransparentButton>
                </li>
              ))}
            </OptionsList>
          )}
        </ChangeUserWrapper>
      ) : (
        <Text>{selectedUser.username}</Text>
      )}
    </Box>
  );
};

export default MultipleUserSelector;
