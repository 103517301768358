import apiFetch from '../core/apiFetch';
import { checkStatus, handleError } from '../../utils/api';
import { getAuthTokenAsync } from '../../utils/authToken';
import { UNEXPECTED_ERROR, INVALID_EMAIL } from '../../constants/errors';
import { LoginUser } from '../../interfaces/login';

export interface ErrorResponse {
  error: {
    code: string;
    message: string[];
  };
}

export interface SuccessResponse {
  user: LoginUser;
}

const searchLoginUserAsync = async (
  username: string,
  birthdate: null | string = null,
  affiliationId: null | string = null
): Promise<ErrorResponse | SuccessResponse> => {
  const token = await getAuthTokenAsync();
  let url = `api/v1/login/user/search?username=${encodeURIComponent(username)}`;
  if (birthdate) {
    url = `${url}&birthdate=${birthdate}`;
  }
  if (affiliationId) {
    url = `${url}&affiliation_id=${affiliationId}`;
  }

  try {
    const response = await apiFetch(token, url, {
      method: 'GET',
    });

    await checkStatus(response);

    const { user } = await response.json();

    return { user: { ...user, firstName: user.first_name } };
  } catch (error) {
    const { message, response } = error;

    if (response.status === 422) {
      const payload = await response.json();

      return {
        error: { code: INVALID_EMAIL, message: payload.errors.base || [] },
      };
    }

    handleError('unexpected status - search login user', error);
    return { error: { code: UNEXPECTED_ERROR, message: [message] } };
  }
};

export default searchLoginUserAsync;
