import apiFetch from '../core/apiFetch';
import { checkStatus, handleError } from '../../utils/api';
import { getAuthTokenAsync } from '../../utils/authToken';
import { UNEXPECTED_ERROR, INVALID_EMAIL } from '../../constants/errors';
import { PasswordReset } from '../../interfaces/login';

export interface ErrorResponse {
  error: {
    code: string;
    message: string[];
  };
}

export interface SuccessResponse {
  success: string;
}

const postPasswordResetAsync = async ({
  userId,
  verificationMethod,
}: PasswordReset): Promise<ErrorResponse | SuccessResponse> => {
  const token = await getAuthTokenAsync();
  const url = `api/v1/users/reset_password`;

  try {
    const response = await apiFetch(token, url, {
      method: 'POST',
      body: JSON.stringify({
        user_id: userId,
        verification_method: verificationMethod,
      }),
    });

    await checkStatus(response);

    const { success } = await response.json();

    return { success };
  } catch (error) {
    const { message, response } = error;

    if (response.status === 422) {
      const payload = await response.json();

      return {
        error: { code: INVALID_EMAIL, message: payload.errors.base || [] },
      };
    }

    handleError('unexpected status - get login user', error);
    return { error: { code: UNEXPECTED_ERROR, message: [message] } };
  }
};

export default postPasswordResetAsync;
